<template>
  <span class="markup-link-container"
        :class="{ 'markup-link-text': !isSlotHtml, ellipsis: !isSlotHtml }" :title="urlForTitle"
        @click="openDialog">
    <slot>
      {{ getDialogLink(resultData) }}
    </slot>
  </span>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "MarkupLink",
  data() {
    return {
    }
  },
  props: {
    resultData: Object,
    datetime: String,
    resultType: String,
  },
  computed: {
    ...mapGetters("highlight", { highlightMode: "highlightMode" }),

    isSlotHtml() {
      return this.$slots.default && typeof this.$slots.default()[0].children !== 'string';
    },
    dialogAllowed() {
      return this.resultType !== 'inlinekp';
    },
    urlForTitle() {
      return this.resultData ? this.resultData.url : '';
    }
  },
  methods: {
    ...mapActions("markup", ["setMarkupResultData"]),
    openDialog(e) {
      if (this.highlightMode || e.ctrlKey) {
        // ctrlKey indicates they are selecting items for outlining. For that or for highlighting we don't want to bring up markup.
        return;
      }

      if (this.dialogAllowed) {
        this.setMarkupResultData({
          resultData: this.resultData,
          ts: Date.parse(this.datetime) / 1000,
          resultType: this.resultType,
        });
      }
    },
    getDialogLink(resultData) {
      return this.resultData.linktext ? this.resultData.linktext : this.urlForDisplay(resultData.url);
    }
  },
};
</script>

<style lang="scss" scoped>
.markup-link-container {
  cursor: pointer;
}

.markup-link-text {
  color: black;
}

.accordion-body .markup-link-container {
  border-top: 1px solid var(--bs-accordion-border-color);
  padding-top: 3px;
}
</style>
