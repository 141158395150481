<template>
  <result-wrapper :element-data="elementData" class="news-feed-standard">
    <div class="detail-container markup-container">
      <div class="page-title">
        <div>
          <favicon :size="faviconSize" :url="elementData.detailSection.url" />
          <a :href="elementData.detailSection.url" target="_blank">{{ elementData.detailSection.title }}</a>
        </div>
      </div>
      <markup-link class="card-title"
                   :resultData="elementData.detailSection"
                   :datetime="elementData.card.datetime"
                   :resultType="elementData.resulttype">
        {{ elementData.detailSection.articleTitle }}
      </markup-link>
      <span class="desc" v-html="elementData.detailSection.desc"></span>
      <div class="published" :title="elementData.card.timezone">{{ displayTime }}</div>
    </div>
    <div class="image" v-if="elementData.detailSection.image">
      <img :src="elementData.detailSection.image" :alt="elementData.detailSection.image" />
    </div>
  </result-wrapper>
</template>

<script>
import ResultWrapper from "@/components/card/ResultWrapper.vue";
import MarkupLink from "@/components/card/MarkupLink.vue";
import Favicon from "@/components/Favicon.vue";
import useTimeHelper from "@/composables/useTimeHelper";
import { mapGetters } from "vuex";

export default {
  name: "NewsFeedStandard",
  props: {
    elementData: Object,
  },
  components: {
    MarkupLink,
    ResultWrapper,
    Favicon,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters("toolbar", { zoomSelection: "zoomSelection" }),

    faviconSize() {
      return this.zoomSelection == 'regular-view' ? 13 : 12;
    },
    displayTime() {
      return this.getAgoTime(this.elementData.detailSection.publishedMinutes, this.elementData.card.datetime, this.elementData.card.timezone);
    },
  },
  setup() {
    const { getAgoTime } = useTimeHelper();
    return {
      getAgoTime,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_icons.scss";
@import "@/scss/_mixins.scss";

.news-feed-standard {

  .result-container .detail-container.markup-container {
    // all this specificity to make it override
    flex-grow: 1;
    justify-content: space-between;

    .page-title {
      overflow: clip;

      &>div {
        display: flex;
        align-items: center;
        gap: .3rem;

        a {
          text-decoration: none;
          color: var(--secondary-text-color);
          white-space: nowrap;
        }
      }
    }
  }

  .image {

    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


@mixin zoomViewSizes($type) {

  .news-feed-standard.#{$type}-view {

    .detail-container {
      margin: var(--timeline-result-margin-#{$type});
      margin-top: 0;
      padding-top: var(--result-extra-padding-#{$type});
      padding-bottom: var(--result-extra-padding-#{$type});

      &>* {
        margin-left: var(--result-extra-padding-#{$type});
      }

      .card-title,
      .desc {
        @include multiline-ellipsis(2);
        max-width: var(--result-text-width-#{$type});
        line-height: 125%;
      }

      .page-title {
        max-width: var(--result-text-width-#{$type});
      }

    }

    .published {
      color: var(--secondary-text-color);
    }

    .image {
      width: var(--news-result-image-size-#{$type});
      height: var(--news-result-image-size-#{$type});
      margin-top: var(--timeline-result-margin-#{$type}-v);
      margin-right: calc(var(--result-extra-padding-#{$type}) + var(--timeline-result-margin-#{$type}-v));
    }
  }
}

@include zoomViewSizes("regular");
@include zoomViewSizes("medium");
</style>
