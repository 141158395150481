<template>
  <div :data-result-id="highlightEnabled ? highlightResultId : ''"
       :data-type-id="highlightEnabled ? highlightTypeId : ''"
       class="highlight-element"
       @mouseover.stop="handleMouseover"
       @mouseout.stop="handleMouseout"
       @click="handleClick"
       :class="{ outlined: isOutlined }">
    <slot />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import commonUtil from "@/utils/commonUtil";


export default {
  name: "HighlightResult",
  emits: ['highlight'],
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    // explicitly specify the type instead of getting it the usual way from elementData. Used by PAA.
    typeId: {
      type: String,
      required: false,
    },
    allowHighlight: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapGetters("timeline", {
      outlinedItems: "outlinedItems",
      stickyOutlinedItems: "stickyOutlinedItems",
      isOutlinePropagationManuallyStopped: "isOutlinePropagationManuallyStopped",
    }),
    ...mapGetters("highlight", {
      highlightMode: "highlightMode",
    }),
    ...mapGetters("routeParams", { isViewHistory: "isViewHistory" }),
    detailSection() {
      return this.elementData.computed?.detailSection // regular result 
        || this.elementData; // sub result
    },
    highlightEnabled() {
      return this.allowHighlight && this.highlightMode;
    },
    highlightResultId() {
      return [
        this.highlightTypeId,
        this.elementData.card?.datetime,
        this.elementData.card?.pollid,
        this.elementData.position,
        this.elementData.rightpos,
        this.elementData.resulttype,
      ].filter(item => item).join('-');
    },
    outlineTypeId() {
      return this.getTypeId(true);
    },
    highlightTypeId() {
      return this.getTypeId(false);
    },
    domainId() {
      return this.detailSection.url ? commonUtil.extractDomain(this.detailSection.url) : '';
    },
    isOutlinedSticky() {
      return this.stickyOutlinedItems.indexOf(this.outlineTypeId) >= 0;
    },
    isOutlined() {
      return this.isOutlinedSticky || (this.outlinedItems.indexOf(this.outlineTypeId) >= 0);
    },
  },
  methods: {
    ...mapActions("timeline", [
      "addOutlinedItem",
      "removeOutlinedItem",
      "toggleStickyOutlinedItem",
      "clearStickyOutlinedItems",
      "setResultTypeOpen",
      "manuallyStopOutlinePropagation",
    ]),
    ...mapActions("highlight", ["addHighlighted"]),
    handleMouseover() {
      this.addOutlinedItem(this.outlineTypeId);
    },
    handleMouseout() {
      this.removeOutlinedItem(this.outlineTypeId);
    },
    handleClick(e) {
      if (this.isOutlinePropagationManuallyStopped) {
        return;
      }

      this.manuallyStopOutlinePropagation();
      if (e.ctrlKey) {
        this.toggleStickyOutlinedItem({ id: this.outlineTypeId });
      } else {
        const isOutlinedSticky = this.isOutlinedSticky; // save this so we can still use the info after clear is called
        this.clearStickyOutlinedItems();
        this.toggleStickyOutlinedItem({ id: this.outlineTypeId, on: !isOutlinedSticky });
      }

      if (this.highlightEnabled) {
        this.addHighlighted({
          resultId: this.highlightResultId,
          typeId: this.highlightTypeId,
          domainId: this.domainId,
        });
      }
    },
    getTypeId(forOutline) {
      if (this.typeId) {
        return this.typeId;
      }

      // KP has its own way of comparing items for highlighting/outline
      if (this.elementData.iskp || this.elementData.computed?.containerDetailSections) {
        return this.elementData.resulttype;
      }
      // outline when not in history view works on the domain level
      else if (forOutline && !this.isViewHistory) {
        return this.domainId || this.detailSection.resulttype;
      }
      // otherwise - compare URLs/result types
      else {
        // need url and not urlid because domain highlighting relies on the url
        return this.detailSection.url || this.detailSection.resulttype;
      }
    },

  },
};
</script>

<style lang="scss" scoped>
// using important here to override any other borders temporarily while hovering
.highlight-element {
  border-width: var(--highlight-outline-width) !important;
  border-style: solid;
  cursor: default;
  overflow-x: hidden;

  &:not(.small-view) {
    border-color: transparent;
    // used as a separator instead of borders so as not to interfere with highlighting
    box-shadow: 0px -1px 0px var(--timeline-result-separator-color);
  }

  &.small-view {
    border-color: var(--timeline-result-border-color-small);
    overflow: hidden;
  }

  &.outlined {
    border-color: black !important;
  }
}
</style>
