import { MEDIUM_DATE_FORMAT } from "@/constants";


export default function useTimeHelper() {

  /**
   * Returns a formatted time string based on a base time (poll time) and an offset of a number of minutes ago, in the given timezone,
   * and handles DST. If the offset is an exact number of hours, it's assumed that the minutes are approximate, the time is rounded up
   * to the nearest hour, and the string contains an indication that the time is approximate. If the offset is an exact number of days
   * then only the date is returned.
   * For e.g.:
   * - if the base time is 6pm UTC, the offset is 60 minutes and the timezone is NY, and the date is in the winter when there
   * is no DST, it will use 12pm as the time and show the date/time as approximate.
   * - if the base time is 5:30pm UTC, the offset is 30 minutes and the timezone is NY, and the date is in the summer when there
   * is DST, it will use 1pm as the time and show the date/time as exact.
   * 
   * @param {Number} minutesAgo number of minutes offset from the base time
   * @param {String} isoTimeStringUTC the base time in UTC from which to calculate the offset is ISO format.
   * The string should either not contain timezone info or it should have UTC timezone info.
   * @param {String} timezoneName A canonical timezone identifier, used to display the time in that timezone. 
   * @returns The formatted date and time string 
   */
  function getAgoTime(minutesAgo, isoTimeStringUTC, timezoneName) {
    const byDay = minutesAgo % 1440 == 0;
    const byHour = minutesAgo % 60 == 0;
    const datetime = this.$dateTime.fromISO(isoTimeStringUTC, { zone: "utc" }).setZone(timezoneName);
    let time = datetime.minus({ minutes: minutesAgo });

    if (byHour) {
      time = time.startOf('hour').plus({ hour: 1 });
    }

    const prefix = byHour ? "~" : "";
    const formattedTime = time.toFormat(MEDIUM_DATE_FORMAT + (byDay ? "" : ", h:mm a"));
    return prefix + formattedTime;
  }

  return {
    getAgoTime,
  };
}
