<template>

  <div class="news-feed-topic">
    <div class="topic-title">{{ title }}</div>
    <div class="grid-container" :style="gridStyle">
      <highlight-result v-for="(article, index) in articles" :key="elementKey + index"
                        class="article"
                        :class="[getMarkupClasses(article), { tall: article.isTall, 'is-left': article.gridPosition.col == 1 }]"
                        :element-data="article"
                        :style="article.style">

        <div class="article-container markup-container">
          <div class="image image-large" v-if="article.image && article.isTall">
            <img :src="article.image" :alt="article.image" />
          </div>
          <div class="article-content">
            <div class="text-content">
              <div>
                <div class="page-title">
                  <div>
                    <favicon :size="faviconSize" :url="article.url" />
                    <a :href="article.url" target="_blank" :title="article.title">{{ article.title }}</a>
                  </div>
                </div>
                <markup-link class="card-title"
                             :resultData="article"
                             :datetime="elementData.card.datetime"
                             :resultType="article.resulttype">
                  {{ article.articleTitle }}
                </markup-link>
              </div>
              <div class="published" :title="elementData.card.timezone">{{ article.displayTime }}</div>
            </div>
            <div class="image image-small" v-if="article.image && !article.isTall">
              <img :src="article.image" :alt="article.image" />
            </div>
          </div>
        </div>
      </highlight-result>
    </div>
  </div>
</template>

<script>
import commonUtil from "@/utils/commonUtil";
import HighlightResult from "@/components/card/HighlightResult.vue";
import MarkupLink from "@/components/card/MarkupLink.vue";
import Favicon from "@/components/Favicon.vue";
import useTimeHelper from "@/composables/useTimeHelper";
import { mapGetters } from "vuex";

export default {
  name: 'NewsFeedTopic',
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    Favicon,
    MarkupLink,
    HighlightResult,
  },
  computed: {
    ...mapGetters("toolbar", { zoomSelection: "zoomSelection" }),

    faviconSize() {
      return this.zoomSelection == 'regular-view' ? 13 : 12;
    },
    title() {
      return this.elementData.title;
    },
    articles() {
      // https://stackoverflow.com/questions/53714999/assign-local-variable-during-a-v-for-loop-in-vue-js
      return this.elementData.containerDetailSections.map(article => {
        const computedProps = {
          style: this.getArticleStyle(article),
          isTall: article.gridPosition.rowspan > 1,
          displayTime: this.getDisplayTime(article),
          domain: commonUtil.extractDomain(article.url),
          resulttype: this.elementData.resulttype,
        };
        return { ...computedProps, ...article };
      });
    },
    gridSize() {
      const maxRow = Math.max(...this.articles.map(a => a.gridPosition.row + (a.gridPosition.rowSpan || 1) - 1));
      const maxCol = Math.max(...this.articles.map(a => a.gridPosition.col + (a.gridPosition.colSpan || 1) - 1));
      return { rows: maxRow, cols: maxCol };
    },
    gridStyle() {
      return {
        display: 'grid',
        gridTemplateColumns: `repeat(${this.gridSize.cols}, 1fr)`,
        gridTemplateRows: `repeat(${this.gridSize.rows}, auto)`,
        gap: '1px'
      };
    }
  },
  methods: {
    getArticleStyle(article) {
      const { row, col, rowspan = 1, colspan = 1 } = article.gridPosition;
      return {
        gridRow: `${row} / span ${rowspan}`,
        gridColumn: `${col} / span ${colspan}`,
      };
    },
    getDisplayTime(article) {
      return this.getAgoTime(article.publishedMinutes, this.elementData.card.datetime, this.elementData.card.timezone);
    },
  },
  setup() {
    const { getAgoTime } = useTimeHelper();
    return {
      getAgoTime,
    };
  },

}
</script>

<style lang="scss" scoped>
@import "@/scss/_mixins.scss";

.news-feed-topic {
  border-radius: var(--border-radius-timeline-container);
  border: 0;

  *,
  :slotted(*) {
    .highlight-element {
      // override separator so there's no line at the top to separate from the topic title
      box-shadow: -1px 1px 0 var(--timeline-result-separator-color);
      &.is-left {
        box-shadow: 0px 1px 0 var(--timeline-result-separator-color);
      } 
    }
  }

  .markup-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .article-content {
    display: flex;
    justify-content: space-between;
    height: 100%;

    .text-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .published {
    color: var(--secondary-text-color);
    white-space: nowrap;
  }

  .image {

    &.image-large {
      width: 100%;
    }

    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .page-title {
    overflow: hidden;

    &>div {
      display: flex;
      align-items: center;
      gap: .3rem;

      a {
        text-decoration: none;
        color: var(--secondary-text-color);
        white-space: nowrap;
      }
    }
  }

  .card-title {
    color: var(--link-color);
    font-weight: 500;
    margin: 0;
    @include multiline-ellipsis(4);
    line-height: 125%;
  }
}

@mixin zoomViewSizes($type) {

  .#{$type}-view {

    .article {
      padding-top: calc(var(--timeline-result-margin-#{$type}-v) + var(--result-extra-padding-#{$type}));
      padding-bottom: calc(var(--timeline-result-margin-#{$type}-v) + var(--result-extra-padding-#{$type}));
      margin-right: calc(var(--timeline-result-margin-#{$type}-h));

      .article-container>* {
        padding-left: var(--result-extra-padding-#{$type});
      }

      &:not(.tall) .text-content {
        --extra-spacing-between: .25rem;
        max-width: calc(100% - var(--news-result-image-size-#{$type}) - var(--timeline-result-margin-#{$type}-h) - var(--result-extra-padding-#{$type}) - var(--extra-spacing-between));

        &>div:first-child {
          min-height: var(--news-result-image-size-#{$type});
        }
      }

      &.tall .text-content {
        max-width: 100%;
      }
    }

    .news-feed-standard+.news-feed-topic {
      border-top: solid 1px var(--timeline-result-separator-color);
    }

    .news-feed-topic {

      &:not(:first-of-type) {
        padding-top: var(--timeline-topic-top-spacing-#{$type}-v);
      }

      .topic-title {
        margin: var(--timeline-result-margin-#{$type}-v) 0 0 var(--timeline-result-margin-#{$type}-h);
        padding-left: calc(var(--result-extra-padding-#{$type}) + 3px + .25rem); // space used by markup border
        font-size: calc(var(--timeline-font-size-#{$type}) * 1.2);
        font-weight: 500;
      }
    }

    .image {
      padding-right: var(--result-extra-padding-#{$type});

      &.image-large {
        margin-bottom: var(--timeline-result-margin-#{$type}-v);
      }

      &.image-small {
        width: var(--news-result-image-size-#{$type});
        height: var(--news-result-image-size-#{$type});
        margin-left: var(--timeline-result-margin-#{$type}-h);
      }
    }

    *,
    :slotted(*) {
      &>* {
        font-size: var(--timeline-font-size-#{$type});
      }
    }
  }
}

@include zoomViewSizes("regular");
@include zoomViewSizes("medium");
</style>