import storage from "@/utils/storageUtil";

const state = () => ({
    show_progress: 0,
    in_progress: false, // this is used to set a flag but not show an in progress model
    print_view: false,
    termVersions: [],
    resultTypes: [],
    slidingMenuShow: true,
})

const getters = {
    showProgress: state => state.show_progress,
    inProgress: state => state.in_progress,
    printView: state => state.print_view,
    termVersions: state => state.termVersions,
    resultTypes: state => state.resultTypes,
    slidingMenuShow: state => state.slidingMenuShow,

    // TODO we should refactor better and put this where it's used once
    hasToolbar: (state, getters, rootState, rootGetters) => ['timeline', 'timelineNews', 'kp', 'keywordSummary', 'timelineCompare', 'iresults'].includes(rootGetters['routeParams/view']),
    // if there are other tools added, generalize this
    hasSecondaryToolbar: (state, getters, rootState, rootGetters) => rootGetters['highlight/highlightMode'] &&  ['timeline', 'timelineNews', 'kp'].includes(rootGetters['routeParams/view']), 
}

const actions = {
    setShowProgress({ commit }, payload) {
        commit('SET_SHOW_PROGRESS', payload);
    },
    clearShowProgress({ commit }) {
        commit('CLEAR_SHOW_PROGRESS');
    },
    setInProgress({ commit }, payload) {
        commit('SET_IN_PROGRESS', payload);
    },    
    setPrintView({ commit }, payload) {
        commit('SET_PRINT_VIEW', payload);
    },
    setTermVersions({ commit }, payload) {
        commit('SET_TERM_VERSIONS', payload);
    },
    setResultTypes({ commit }, resultTypes) {
        commit('SET_RESULT_TYPES', resultTypes);
    },
    setSlidingMenuShow({ commit }, show) {
        commit('SET_SLIDING_MENU_SHOW', show);
    },
}

const mutations = {
    SET_SHOW_PROGRESS(state, payload) {
        payload ? ++state.show_progress : --state.show_progress;   
        if (state.show_progress < 0) {
            state.show_progress = 0;
        }
    },
    CLEAR_SHOW_PROGRESS(state) {
        state.show_progress = 0;
    },
    SET_IN_PROGRESS(state, payload) {
        state.in_progress = payload
    },    
    SET_PRINT_VIEW(state, payload) {
        state.print_view = payload
    },
    SET_TERM_VERSIONS(state, payload) {
        state.termVersions = payload
    },
    SET_RESULT_TYPES(state, payload) {
        state.resultTypes = payload;
    },
    SET_SLIDING_MENU_SHOW(state, payload) {
        state.slidingMenuShow = payload;
        storage.set("slidingMenuShow", payload);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
