<template>
  <container-wrapper v-slot="slotProps"
                     :element-key="elementKey"
                     :element-data="elementData"
                     :display-name="'Top stories' + (isHorizontal ? ' carousel' : '')"
                     icon-name="top-stories">

    <div v-for="(newsItem, index) in elementData.news"
         class="accordion-item"
         :key="slotProps.accordionKey + index">
      <highlight-result :element-data="newsItem"
                        :class="getMarkupClasses(newsItem)">

        <div class="accordion-header markup-container markup-container-with-image"
             :id="'heading' + slotProps.accordionKey + index">
          <div class="outer-image-wrapper">
            <div class="image-wrapper">
              <img :src="newsItem.desc || defaultNewsImage" />
            </div>
          </div>
          <div>
            <span class="ellipsis card-title" v-html="newsItem.title" />
            <markup-link :resultData="newsItem" :datetime="elementData.card.datetime"
                         :resultType="newsItem.resulttype" />
          </div>
        </div>

      </highlight-result>
    </div>

  </container-wrapper>
</template>

<script>
import ContainerWrapper from "@/components/card/ContainerWrapper";
import MarkupLink from "@/components/card/MarkupLink.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";
import { DEFAULT_NEWS_IMAGE } from "@/constants";

export default {
  name: "NewsContainer",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    ContainerWrapper,
    MarkupLink,
    HighlightResult,
  },
  computed: {
    isHorizontal() {
      return this.elementData.orientation == 7;
    },
    anyElementOpen() {
      // Check if any question is open
      return this.elementData.news.some(news => this.isOpen(news));
    }
  },
  data() {
    return {
      defaultNewsImage: DEFAULT_NEWS_IMAGE,
    };
  },
};
</script>
