<template>
  <div class="card-wrapper" :class="zoomSelection">
    <card-header :header-data="headerData" :enable-suggestions="false" :page-number="1" :card-type="'kp-card'" />
    <div class="card-content">
      <div class="unhide-text" v-if="isHide">
        <button
                type="button"
                class="btn btn-md btn-primary"
                @click="$emit('showCard', headerData.id)">
          Unhide
        </button>
      </div>
      <div class="unhide-overlay" v-if="isHide" />
        <div class="spinner-border" v-if="isLoading"></div>
        <iframe :srcdoc="htmlContent" scrolling="no" frameborder="no" :id="headerData.id"></iframe>
      </div>
  </div>
</template>

<script>
import CardHeader from "./card/CardHeader.vue";
import { mapGetters } from "vuex";
import HighlightUtil from '../utils/highlightUtil';

export default {
  name: "KPCard",
  emits: ["showCard", "hoverFocus", "highlight", "resize"],
  components: {
    CardHeader,
  },
  props: {
    headerData: Object,
    termData: Object,
    isHide: Boolean,
    hoverFocusItem: Object,
    shouldZoom: {
      type: Boolean,
      required: false,
      default: true,
    },
    isLinksActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      zoom: 1,
      bufferWidth: 10,
      bufferHeight: 20,
      htmlContent: "",
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters("highlight", {
      highlightedKP: "highlightedKP",
    }),
    ...mapGetters('toolbar', { zoomSelection: 'zoomSelection' }),
    ...mapGetters("routeParams", {
      resultset: "resultset",
    }),

    iframe() {
      return document.getElementById(this.headerData.id);
    },
    iframeDocument() {
      return this.iframe.contentWindow.document;
    },
    footerElement() {
      return this.iframeDocument.querySelector('.g-blk');
    },
    iframeStyle() {
      return getComputedStyle(this.iframe);
    },

    /*
     * determines the width of the card
     */
    kpElements() {
      let startElement = this.kpWrapper ? this.kpWrapper : this.iframeDocument;

      let kp = startElement.querySelectorAll('.kp-wholepage');

      if (!kp.length) {
        kp = startElement.querySelectorAll('div[role="complementary"]');
      }

      if (!kp.length) {
        kp = startElement.querySelectorAll('.knowledge-panel');
      }

      if (!kp.length) {
        kp = startElement.querySelectorAll('div[jscontroller="nPaQu"]');
      }

      //      TODO: does this work? Seems like it can't be since it would return a single node instead of a list
      // if (!kp.length) {
      //   kp = startElement.querySelector('#rso .kp-blk');
      // }

      // finds the See results about element when there is no actual KP
      if (!kp.length) {
        kp = startElement.querySelectorAll('block-component div');
      }

      return kp;
    },

    /*
     * determines the height of the card, and what element to position in the corner of the iframe
     */
    kpWrapper() {
      let kp = this.iframeDocument.querySelector('#rhs');

      if (!kp) {
        kp = this.iframeDocument.querySelector('.kp-wholepage');
      }

      if (!kp) {
        kp = this.iframeDocument.querySelector('.knowledge-panel');
      }

      if (!kp) {
        kp = this.iframeDocument.querySelector('#rso .kp-blk');
      }

      return kp;
    },
    cardWidth() {
      let width = 0;
      const kp = this.kpElements;

      if (kp && kp.length) {
        width = (kp[0].clientWidth + this.bufferWidth) * this.zoom;
      }

      return width;
    },
    loadingHtmlComplete() {
      return this.htmlContent === ""; 
    }
  },

  methods: {
    processMobile() {
      const style = document.createElement("style");
      style.textContent = `
      div {
        max-width: ${this.cardWidth}px;
      }
      `;
      this.iframeDocument.head.append(style);
    },

    processKpHtml() {
      if (!this.termData.html) {
        return;
      }

      this.htmlContent = this.termData.html;

      this.iframe.onload = () => {

        if (this.termData && this.termData.hasKP) {

          const body = this.iframeDocument.body;

          if (this.resultset.includes('mobile')) {
            this.processMobile(body);
          }

          const aLinks = body.querySelectorAll('a');
          aLinks.forEach(aLink => {
            if (aLink) {
              if (this.isLinksActive) {
                aLink.setAttribute('target', '_blank');
              } else {
                aLink.style['pointer-events'] = "none";
                aLink.style.cursor = "default";
              }
            }
          });

          // Items for removal 
          const removals = [];
          removals.push(body.querySelector('div[role="dialog"]'));
          removals.push(body.querySelector('#searchform'));
          //removes adds from top
          removals.push(body.querySelector('.cu-container'));

          removals.forEach(removal => {
            if (removal) {
              removal.style.display = "none";
            }
          });

          const kp = this.kpWrapper;
          if (kp) {
            kp.style.position = 'fixed';
            kp.style.top = 0;
            kp.style.left = 0;
            kp.style['z-index'] = '99999';
            kp.style.background = 'white';
            kp.style['padding-left'] = this.bufferWidth / 2 + 'px';
            kp.style['padding-right'] = this.bufferWidth / 2 + 'px';
            kp.style['margin-left'] = 0;
            kp.style['margin-right'] = 0;
            kp.style.width = 'inherit';
          }
        }
        const heightElement = this.iframeDocument.querySelector('.z4oRIf');
        if (heightElement) {
          heightElement.style.height = '100%';
        }

        if (this.termData.hasKP) {
          this.addBackground();
        }
        this.initZoom()

        // Set event listeners
        const dataAttr = this.iframeDocument.querySelectorAll("[data-attrid*=':/']");
        if (dataAttr.length) {
          dataAttr.forEach((node) => {
            node.style.border = "1px solid transparent";

            node.addEventListener("mouseenter", (e) => {
              const emitValue = e.srcElement.getAttribute("data-attrid");
              this.$emit("hoverFocus", {
                value: emitValue,
                isHoverFocus: true,
              });
            });

            node.addEventListener("mouseleave", (e) => {
              const emitValue = e.srcElement.getAttribute("data-attrid");
              this.$emit("hoverFocus", {
                value: emitValue,
                isHoverFocus: false,
              });
            });

            node.addEventListener("click", () => {
              this.$emit('highlight', this.headerData.id);
            });
          });
        }
      };
    },
    addBackground() {
      const box = document.createElement("div");
      box.id = "box";
      box.style.background = "white";
      box.style.width = '600px';
      box.style.height = '2000px';
      box.style['z-index'] = '99998';
      this.iframeDocument.body.prepend(box);
    },
    initZoom() {
      this.setZoom(this.zoomSelection, true);
    },
    setZoom(selection, init = false) {
      const zoom = this.shouldZoom && selection == 'medium-view' ? 0.6 : 1;

      this.iframeDocument.body.style.zoom = zoom;
      this.zoom = zoom;
      this.setCardWidth();
      if (init) {
        this.initCardHeight();
      } else if (this.shouldZoom) {
        const fixZoom = selection == 'medium-view' ? 0.6 : 1 / 0.6;
        this.iframe.height *= fixZoom;
      }
      this.$emit("resize", this.iframeStyle.getPropertyValue('width'), this.iframeStyle.getPropertyValue('height'));
    },
    setCardWidth() {
      if (this.cardWidth > 0) {
        this.iframe.setAttribute("style", "width:" + this.cardWidth + "px !important");
      }
    },
    initCardHeight() {
      if (!this.kpElements) {
        return;
      }

      let kp = this.kpElements.length >= 2 || this.footerElement ? this.kpWrapper : this.kpElements[0];
      
      if (!kp) {
        return;
      }

      const kpSectionComputed = window.getComputedStyle(kp);
      this.iframe.height =
        kp.scrollHeight +
        parseFloat(kpSectionComputed["marginTop"]) +
        parseFloat(kpSectionComputed["marginBottom"]) +
        parseFloat(kpSectionComputed["paddingTop"]) +
        parseFloat(kpSectionComputed["paddingBottom"]) +
        this.bufferHeight;

      this.iframe.height *= this.zoom;
      kp.style.height = '100%';
    },

    updateHighlighting() {
      let styles = '';
      for (const [itemId, color] of Object.entries(this.highlightedKP[this.headerData.id] || {})) {
        styles += `[data-attrid*='${itemId}'] { background-color: ${color} !important; }\n`;
      }
      HighlightUtil.setStyles(styles, this.iframeDocument);
    },
  },
  mounted() {
    if (this.termData) {
      this.processKpHtml();
    }
  },
  watch: {
    termData: {
      deep: true,
      handler: function () {
        if (this.termData) {
          this.processKpHtml();
        }
      },
    },
    hoverFocusItem: {
      deep: true,
      handler: function () {
        const hoverFocusElements = this.iframeDocument.querySelectorAll(
          `[data-attrid*='${this.hoverFocusItem.value}']`
        );
        hoverFocusElements.forEach((hoverFocusElement) => {
          hoverFocusElement.style.borderColor = this.hoverFocusItem.isHoverFocus
            ? "black"
            : "transparent";
        });
      },
    },
    highlightedKP: {
      deep: true,
      handler: function () {
        this.updateHighlighting();
      },
    },
    zoomSelection(currentSelection) {
      if (this.shouldZoom) {
        this.setZoom(currentSelection);
      }
    },
    loadingHtmlComplete(value) {
      this.isLoading = value;
    }
  },
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  position: relative;
  background: var(--timeline-card-bg);
  margin: 0px calc(var(--timeline-between-cards-spacing) / 2);
  border-radius: var(--border-radius-card);

  .card-content {
    .unhide-text {
      position: absolute;
      top: 200px;
      width: 100%;
      z-index: 999;
      color: white;
      text-align: center;
      height: 70px;
    }

    .unhide-overlay {
      position: absolute;
      width: 100%;
      height: calc(100% - 115px);
      background-color: #cacaca;
      z-index: 998;
      opacity: 0.6;
    }

    .spinner-border {
      position: absolute;
      font-size: 26px;
      width: 5rem;
      height: 5rem;
      color: var(--fb-blue-70);
      top: 30%;
      left: 40%;

    }
  }

  &.regular-view {
    .card-content {
      margin: 0 var(--timeline-result-margin-regular);
    }

    iframe {
      width: var(--timeline-card-width-kp-regular);
    }
  }

  &.medium-view {

    .card-content {
      margin: 0 var(--timeline-result-margin-medium);
    }

    iframe {
      width: var(--timeline-card-width-kp-medium);
    }
  }
}
</style>
