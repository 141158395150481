<template>
  <container-wrapper
    v-slot="slotProps"
    :element-key="elementKey"
    :element-data="elementData"
    display-name="Videos"
    icon-name="icons video">
    <div
      v-for="(videoItem, index) in elementData.video"
      :key="slotProps.accordionKey + index"
      class="accordion-item"
    >
      <highlight-result :element-data="videoItem" :class="getMarkupClasses(videoItem)">
        <div
          class="accordion-header markup-container markup-container-with-image"
          :id="'heading' + slotProps.accordionKey + index"
        >
          <div class="outer-image-wrapper">
            <div class="image-wrapper">
              <img :src="videoItem.desc || defaultNewsImage" />
            </div>
          </div>
          <div>
            <span class="card-title video-title ellipsis" v-html="videoItem.title" />
            <markup-link
              :resultData="videoItem"
              :datetime="elementData.card.datetime"
              :resultType="videoItem.resulttype"
            />
          </div>
        </div>
      </highlight-result>
    </div>
  </container-wrapper>
</template>

<script>
import ContainerWrapper from "@/components/card/ContainerWrapper";
import MarkupLink from "@/components/card/MarkupLink.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";
import { DEFAULT_NEWS_IMAGE } from "@/constants";

export default {
  name: "VideoContainer",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    ContainerWrapper,
    MarkupLink,
    HighlightResult,
  },
  data() {
    return {
      defaultNewsImage: DEFAULT_NEWS_IMAGE,
    };
  },
};
</script>

