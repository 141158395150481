<template>
  <container-wrapper v-slot="slotProps"
                     :element-key="elementKey"
                     :element-data="elementData"
                     icon-name="latest-from"
                     :display-name="'Latest from ' + domainName()">


    <div v-for="(newsItem, index) in elementData.news"
         class="accordion-item"
         :key="slotProps.accordionKey + index">

      <highlight-result :element-data="newsItem"
                        :class="getMarkupClasses(newsItem)">
        <div class="accordion-header markup-container markup-container-with-image"
             :id="'heading' + slotProps.accordionKey + index">
          <div class="outer-image-wrapper">
            <div class="image-wrapper">
              <img :src="newsItem.desc || defaultNewsImage" />
            </div>
          </div>
          <div>
            <span class="ellipsis card-title" v-html="newsItem.title" />
            <markup-link :resultData="newsItem" :datetime="elementData.card.datetime"
                         :resultType="newsItem.resulttype" />
          </div>
        </div>
      </highlight-result>
    </div>
  </container-wrapper>
</template>

<script>
import ContainerWrapper from "@/components/card/ContainerWrapper";
import MarkupLink from "@/components/card/MarkupLink.vue";
import HighlightResult from "@/components/card/HighlightResult.vue";

export default {
  name: "LatestFrom",
  props: {
    elementKey: String,
    elementData: Object,
  },
  components: {
    ContainerWrapper,
    MarkupLink,
    HighlightResult,
  },
  methods: {
    domainName() {
      const news = this.elementData?.news;
      if (!news || news.length === 0) {
        return "";
      }
      const url = news[0]?.url;
      if (!url) {
        return "(No URL Available)";
      }
      const a = document.createElement("a");
      a.href = url;
      return a.hostname;
    },
  },
};
</script>
